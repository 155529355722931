import { combineReducers, configureStore } from "@reduxjs/toolkit";
import phoneReducer from "../ui/PhoneSelector/PhoneSelectorSlice";
import loadingReducer from "../ui/Loading/LoadingSlice";
import VerificationCodeSlice from "../ui/VerificationCode/VerificationCodeSlice";
import apiReducer from "../components/ApiProvider/ApiProviderSlice";
import agreementReducer from "../components/AccountManagement/Components/Agreement/AgreementSlice";
import api from "./middleware/api";
import toast from "./middleware/toast";
import captureImageReducer from "../ui/Drone/Capture/CaptureSlice";
import gimbalImageReducer from "../ui/Drone/Gimbal/GimbalSlice";
import appContextSlice from "./ducks/appContextSlice";
import WorkspaceSlice from "../store/WorkspaceSlice";
import UserProfileSlice from "../store/UserProfileSlice";
import accountStateSlice from "./ducks/accountStateSlice";
import UserManagementSlice from "../components/AdminCenter/AdminApplications/UserManagement/UserManagementSlice";
import OperationStepSlice from "../components/AdminCenter/AdminOperationManager/OperationStepSlice";
import DialogSlice from "../ui/Dialog/DialogSlice";
import TableSlice from "../ui/Table/TableSlice";
import RoleManagementSlice from "../components/AdminCenter/AdminApplications/RoleManagement/RoleManagementSlice";
import ToastSlice from "../ui/Toast/ToastSlice";
import AccountManagementSlice from "../components/AccountManagement/AccountManagementSlice";
import GroupManagementSlice from "../components/AdminCenter/AdminApplications/GroupManagement/GroupManagementSlice";
import CustomerManagementSlice from "../components/AdminCenter/AdminApplications/CustomerManagement/CustomerManagementSlice";
import GuestManagementSlice from "../components/AdminCenter/AdminApplications/GuestManagement/GuestManagementSlice";
import OnboardDeviceAppSlice from "../components/Dashboard/ApplicationsArea/DeviceApp/OnboardDeviceApp/OnboardDeviceAppSlice";
import GroupAppSlice from "../ui/GroupApp/GroupAppSlice";
import DeviceManagementSlice from "../components/AdminCenter/AdminApplications/DeviceMangement/DeviceManagementSlice";
import FlightControllerSlice from "../components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import WebsocketSlice from "../components/Dashboard/Components/WebsocketHandler/WebsocketSlice";
import StorageManagementSlice from "../components/AdminCenter/AdminApplications/StorageManagement/StorageManagementSlice";
import StorageContainerSlice from "../components/AdminCenter/AdminApplications/StorageManagement/StorageContainerSlice";
import StorageContainerAppSlice from "../components/Dashboard/ApplicationsArea/StorageApp/StorageContainerApp/StorageContainerAppSlice";
import AppTableSlice from "../ui/AppTable/AppTableSlice";
import SettingsSlice from "../helper/settingsSlice";
import IdentitySlice from "src/components/Dashboard/Components/WebsocketHandler/IdentitySlice";
import AppOperationStepSlice from "src/components/Dashboard/Components/ApplicationOperationManager/AppOperationStepSlice";
import UserVerificationSlice from "src/store/UserVerificationSlice";
import NotificationSlice from "src/components/Notification/NotificationSlice";
import DockManagerAppSlice from "src/components/Dashboard/ApplicationsArea/DeviceApp/DockManagerApp/DockManagerAppSlice";
import DockIdentitySlice from "src/components/Dashboard/Components/WebsocketHandler/DockIdentitySlice";
import UiSlice from "src/store/UiSlice";
import SecurityAppSlice from "src/components/Dashboard/ApplicationsArea/SecurityApp/SecurityAppSlice";
import DeviceMapSlice from "src/components/DeviceMap/DeviceMapSlice";
import DeviceServiceSlice from "src/services/device/DeviceServiceSlice";
import MissionServiceSlice from "src/services/mission/MissionServiceSlice";
import LogServiceSlice from "src/services/log/LogServiceSlice";
import ExploreAppSlice from "src/components/Dashboard/ApplicationsArea/ExploreApp/ExploreAppSlice";
import UserServiceSlice from "src/services/user/UserServiceSlice";
import DockManagementSlice from "src/components/AdminCenter/AdminApplications/DockManagement/DockManagementSlice";
import FlightServiceSlice from "src/services/flight/FlightServiceSlice";

const reducer = combineReducers({
  phone: phoneReducer,
  verificationCode: VerificationCodeSlice,
  account: AccountManagementSlice,
  loading: loadingReducer,
  api: apiReducer,
  agreement: agreementReducer,
  capture: captureImageReducer,
  gimbal: gimbalImageReducer,
  context: appContextSlice,
  workspace: WorkspaceSlice,
  profile: UserProfileSlice,
  accountState: accountStateSlice,
  user: UserManagementSlice,
  operation: OperationStepSlice,
  dialog: DialogSlice,
  table: TableSlice,
  role: RoleManagementSlice,
  toast: ToastSlice,
  group: GroupManagementSlice,
  customer: CustomerManagementSlice,
  guest: GuestManagementSlice,
  onboard: OnboardDeviceAppSlice,
  groupApp: GroupAppSlice,
  device: DeviceManagementSlice,
  flight: FlightControllerSlice,
  websocket: WebsocketSlice,
  storage: StorageManagementSlice,
  container: StorageContainerSlice,
  containerApp: StorageContainerAppSlice,
  appTable: AppTableSlice,
  settings: SettingsSlice,
  identities: IdentitySlice,
  dockIdentities: DockIdentitySlice,
  operation: AppOperationStepSlice,
  verification: UserVerificationSlice,
  notification: NotificationSlice,
  dockManager: DockManagerAppSlice,
  dock: DockManagementSlice,
  ui: UiSlice,
  securityApp: SecurityAppSlice,
  exploreApp: ExploreAppSlice,
  deviceMap: DeviceMapSlice,
  deviceService: DeviceServiceSlice,
  missionService: MissionServiceSlice,
  logService: LogServiceSlice,
  userService: UserServiceSlice,
  flightService: FlightServiceSlice,
});

const middleware = [api, toast];

const store = configureStore({
  reducer,
  middleware: () => middleware,
});
// sagaMiddleware.run();

export default store;
