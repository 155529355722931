import { useEffect, useRef } from "react";
import { useStore } from "react-redux";
import { dockConnectionManager } from "src/helper/HubConnectionManager";
import useCurrentUserId from "src/helper/useCurrentUserId";
import { generateId } from "src/helper/utils";
import { djiCloudCustomMethod, djiCloudMethod } from "src/services/common/constants";
import { getDeviceInfo, getSubDeviceInfo } from "src/services/device/common/deviceUtils";
import useDeviceConnection from "src/services/device/common/useDeviceConnection";
import useDeviceInfo from "src/services/device/common/useDeviceInfo";

export default function useCloudLiveFlight(deviceId) {
  const deviceInfo = useDeviceInfo(deviceId);
  const connection = useDeviceConnection(deviceId);
  const groupHandlerIds = useRef([]);
  const groupInstantHanlderIds = useRef([]);
  const currUserId = useCurrentUserId();

  const sendReturnHome = (onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.wayline.RETURN_HOME) {
            console.log('🏠 RETURN_HOME_RESULT', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.wayline.RETURN_HOME,
      data: null,
    });
  }

  const sendCancelReturnHome = (onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.wayline.RETURN_HOME_CANCEL) {
            console.log('🏠 RETURN_HOME_CANCEL_RESULT', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.wayline.RETURN_HOME_CANCEL,
      data: null,
    });
  }

  const sendFlightAuthorityGrab = (onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.FLIGHT_AUTHORITY_GRAB) {
            console.log('👑 FLIGHT_AUTHORITY_GRAB_RESULT', message.data);
            typeof onReply === 'function' && onReply(message.data);
            dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.FLIGHT_AUTHORITY_GRAB,
    });
  }

  const sendOneKeyTakeoff = (data, onReply, onProgress) => {
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.TAKEOFF_TO_POINT) {
            console.log('🕹️ ONE_KEY_TAKEOFF_RESULT', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
      {
        identity: deviceId,
        name: [connection?.group?.join?.events],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.TAKEOFF_TO_POINT_PROGRESS) {
            console.log('🕹️✨ ONE_KEY_TAKEOFF_PROGRESS', message.data);

            if(message.need_reply) {
              const targetGroup = connection?.group?.send?.events_reply;

              dockConnectionManager.sendToDjiCloudGroup(deviceId, targetGroup, {
                method: djiCloudMethod.liveFlight.TAKEOFF_TO_POINT_PROGRESS,
                data: {
                  result: 0,
                },
                bid: message.bid, 
                tid: message.tid,
              });
            }

            onProgress && onProgress(message.data);
          }
        },
      },
    ], 'mission-tracking');

    const flightId = data?.flight_id || generateId();

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.TAKEOFF_TO_POINT,
      gateway: deviceInfo?.serialNumber,
      data: {
        commander_flight_height: 2,
        commander_mode_lost_action: 0,
        commander_flight_mode: 1,
        flight_id: flightId,
        max_speed: 12,
        rth_mode: 1,
        rc_lost_action: 0,
        rth_altitude: 20,
        security_takeoff_height: 21,
        timestamp: Date.now(),
        controller_id: currUserId,
        // simulate_mission: {
        //   is_enable: 1,
        //   latitude: deviceInfo?.coordinates?.lat,
        //   longitude: deviceInfo?.coordinates?.long,
        // },
        ...data,
      },
    });
  }

  const sendFlyToPoint = (data, onReply) => {
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.FLY_TO_POINT) {
            console.log('🕹️ FLY_TO_POINT_RESULT', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
      {
        identity: deviceId,
        name: [connection?.group?.join?.events],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.FLY_TO_POINT_PROGRESS) {
            console.log('🕹️✨ FLY_TO_POINT_PROGRESS', message.data);

            if(message.need_reply) {
              const targetGroup = connection?.group?.send?.events_reply;

              dockConnectionManager.sendToDjiCloudGroup(deviceId, targetGroup, {
                method: djiCloudMethod.liveFlight.FLY_TO_POINT_PROGRESS,
                data: {
                  result: 0,
                },
                bid: message.bid, 
                tid: message.tid,
              });
            }
          }
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.FLY_TO_POINT,
      gateway: deviceInfo?.serialNumber,
      timestamp: Date.now(),
      userId: currUserId,
      data: {
        fly_to_id: generateId(),
        timestamp: Date.now(),
        controller_id: currUserId,
        ...data,
      },
    });
  }

  const sendStopFlyto = (onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.FLY_TO_POINT_STOP) {
            console.log('🕹️ FLY_TO_POINT_STOP_RESULT', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.FLY_TO_POINT_STOP,
      data: null,
    });
  }

  const sendStartDrcChannel = (data = {}, onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupInstantHanlderIds.current);
    groupInstantHanlderIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.commands_reply],
        handler: (message) => {
          if(message.method === djiCloudCustomMethod.liveFlight.START_DRC_CHANNEL_RESPONSE) {
            console.log('🚁 START DRC CHANNEL RESPONSE', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');

    const serialNumber = getSubDeviceInfo(deviceId)?.serialNumber;
    const gateway = getDeviceInfo(deviceId)?.serialNumber;

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.commands, {
      method: djiCloudCustomMethod.liveFlight.START_DRC_CHANNEL,
      gateway,
      data: {
        serialNumber,
        osd: 1,
        hsi: 1,
        userId: currUserId,
        ...data,
      }
    });
  }

  const sendStopDrcChannel = (data = {}, onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupInstantHanlderIds.current);
    groupInstantHanlderIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.commands_reply],
        handler: (message) => {
          if(message.method === djiCloudCustomMethod.liveFlight.STOP_DRC_CHANNEL_RESPONSE) {
            console.log('🚁 STOP DRC CHANNEL RESPONSE', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');

    const serialNumber = getSubDeviceInfo(deviceId)?.serialNumber;
    const gateway = getDeviceInfo(deviceId)?.serialNumber;

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.commands, {
      method: djiCloudCustomMethod.liveFlight.STOP_DRC_CHANNEL,
      gateway,
      data: {
        serialNumber,
        ...data,
      }
    });
  }

  const getDrcChannelStatus = (data = {}, onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupInstantHanlderIds.current);
    groupInstantHanlderIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.commands_reply],
        handler: (message) => {
          if(message.method === djiCloudCustomMethod.liveFlight.GET_DRC_CHANNEL_STATUS_RESPONSE) {
            console.log('🚁 GET DRC CHANNEL STATUS RESPONSE', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');

    const serialNumber = getSubDeviceInfo(deviceId)?.serialNumber;
    const gateway = getDeviceInfo(deviceId)?.serialNumber;

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.commands, {
      method: djiCloudCustomMethod.liveFlight.GET_DRC_CHANNEL_STATUS,
      gateway,
      data: {
        serialNumber,
        ...data,
      }
    });
  }

  const sendDrcDroneControl = (data, onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupInstantHanlderIds.current);
    groupInstantHanlderIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.up],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.DRONE_CONTROL) {
            console.log('🕹️ DRONE_CONTROL_RESULT', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.down, {
      method: djiCloudMethod.liveFlight.DRONE_CONTROL,
      data,
    });
  }

  const sendCameraScreenDrag = (data, onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupInstantHanlderIds.current);
    groupInstantHanlderIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.CAMERA_SCREEN_DRAG) {
            console.log('🕹️ CAMERA_SCREEN_DRAG', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.CAMERA_SCREEN_DRAG,
      data,
    });
  }

  const sendGimbalReset = (data, onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupInstantHanlderIds.current);
    groupInstantHanlderIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.GIMBAL_RESET) {
            console.log('🕹️ GIMBAL RESET', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.GIMBAL_RESET,
      data,
    });
  }

  const sendCameraFocalLengthSet = (data, onReply) => {
    dockConnectionManager.unsubscribeGroupMessages(groupInstantHanlderIds.current);
    groupInstantHanlderIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === djiCloudMethod.liveFlight.CAMERA_FOCAL_LENGTH_SET) {
            console.log('🕹️ CAMERA_FOCAL_LENGTH_SET', message.data);
            typeof onReply === 'function' && onReply(message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.CAMERA_FOCAL_LENGTH_SET,
      data,
    });
  }

  useEffect(() => {
    return () => {
      dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    }
  }, []);

  return {
    sendReturnHome,
    sendCancelReturnHome,
    sendFlightAuthorityGrab,
    sendOneKeyTakeoff,
    sendFlyToPoint,
    sendStopFlyto,
    sendCameraScreenDrag,
    sendGimbalReset,
    sendCameraFocalLengthSet,
    sendStartDrcChannel,
    sendStopDrcChannel,
    getDrcChannelStatus,
    sendDrcDroneControl,
  }
}
