import './DebugPanel.sass'
import CollapsablePanel from '../../../common/CollapsablePanel/CollapsablePanel';
import Progress from 'src/hci/common/Progress/Progress';
import Badge from 'src/hci/common/Badge/Badge';
import Button from 'src/hci/common/Button/Button';
import {TbBolt, TbPlayerPauseFilled, TbPlayerPlayFilled, TbPlayerStopFilled } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { removeMission } from 'src/services/mission/MissionServiceSlice';
import { MissionStatus, NotActiveMissionStatusSet } from 'src/services/mission/common/missionConstants';
import classNames from 'classnames';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import { useRef } from 'react';
import { generateId } from 'src/helper/utils';
import { djiCloudCustomMethod } from 'src/services/common/constants';

export default function DebugPanel({ entity, onClose, collapsed, missionController }) {
  const deviceInfo = useDeviceInfo(entity?.id);
  const connection = useDeviceConnection(entity?.id);
  const dockGroupHandlerIds = useRef([]);

  const handleClose = () => {
    onClose?.();
  }

  const sendActivateRemoteDebugging = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'debug_mode_open') {
            console.log('🕹️ DEBUG_MODE_OPEN_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'debug_mode_open',
      data: null,
    });
  }

  const sendDeactivateRemoteDebugging = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'debug_mode_close') {
            console.log('🕹️ DEBUG_MODE_CLOSE_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'debug_mode_close',
      data: null,
    });
  }

  const sendTurnOnDrone = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'drone_open') {
            console.log('🕹️ DRONE_OPEN_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'drone_open',
      data: null,
    });
  }

  const sendTurnOffDrone = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'drone_close') {
            console.log('🕹️ DRONE_CLOSE_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'drone_close',
      data: null,
    });
  }

  const sendOpenCover = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'cover_open') {
            console.log('🕹️ COVER_OPEN_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'cover_open',
      data: null,
    });
  }

  const sendCloseCover = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'cover_close') {
            console.log('🕹️ COVER_CLOSE_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'cover_close',
      data: null,
    });
  }

  const sendReboot = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'device_reboot') {
            console.log('🕹️ REBOOT_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'device_reboot',
      data: null,
    });
  }

  const sendReturnHome = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'return_home') {
            console.log('🏠 RETURN_HOME_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'return_home',
      data: null,
    });
  }

  const sendCancelReturnToHome = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'return_home_cancel') {
            console.log('🏠 CANCEL_RETURN_HOME_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'return_home_cancel',
      data: null,
    });
  }

  const sendFlightAuthorityGrab = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'flight_authority_grab') {
            console.log('👑 FLIGHT_AUTHORITY_GRAB_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'flight_authority_grab',
      data: {},
    });
  }

  const sendOneKeyTakeoff = () => {
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'takeoff_to_point') {
            console.log('🕹️ ONE_KEY_TAKEOFF_RESULT', message.data);
          }
        },
      },
      {
        identity: entity?.id,
        name: [connection?.group?.join?.events],
        handler: (message) => {
          if(message.method === 'takeoff_to_point_progress') {
            console.log('🕹️✨ ONE_KEY_TAKEOFF_PROGRESS', message.data);
          }
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'takeoff_to_point',
      data: {
        commander_flight_height: 2,
        commander_mode_lost_action: 0,
        commander_flight_mode: 1,
        flight_id: generateId(),
        max_speed: 12,
        rth_mode: 1,
        rc_lost_action: 0,
        rth_altitude: 20,
        security_takeoff_height: 21,
        target_height: 85,
        target_latitude: deviceInfo?.coordinates?.lat,
        target_longitude: deviceInfo?.coordinates?.long,
        simulate_mission: {
          is_enable: 1,
          latitude: deviceInfo?.coordinates?.lat,
          longitude: deviceInfo?.coordinates?.long,
        }
      },
    });
  }

  const sendFlyToPoint = () => {
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'fly_to_point') {
            console.log('🕹️ FLY_TO_POINT_RESULT', message.data);
          }
        },
      },
      {
        identity: entity?.id,
        name: [connection?.group?.join?.events],
        handler: (message) => {
          if(message.method === 'fly_to_point_progress') {
            console.log('🕹️✨ FLY_TO_POINT_PROGRESS', message.data);
          }
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'fly_to_point',
      data: {
        fly_to_id: generateId(),
        max_speed: 12,
        points: [
          {
            latitude: 1.3567148,
            longitude: 103.8376885,
            height: 85,
          },
        ],
        simulate_mission: {
          is_enable: 1,
          latitude: deviceInfo?.coordinates?.lat,
          longitude: deviceInfo?.coordinates?.long,
        }
      },
    });
  }

  const sendStopFlyto = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'fly_to_point_stop') {
            console.log('🕹️ FLY_TO_POINT_STOP_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');


    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.services, {
      method: 'fly_to_point_stop',
      data: null,
    });
  }

  const sendTerminateMission = () => {
    dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: entity?.id,
        name: [connection?.group?.join?.commands_reply],
        handler: (message) => {
          if(message.method === djiCloudCustomMethod.mission.MISSION_TERMINATE) {
            console.log('🕹️ TERMINATE_MISSION_SENT', message.data);
          }
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(entity?.id, connection?.group?.send?.commands, {
      method: djiCloudCustomMethod.mission.MISSION_TERMINATE,
      gateway: deviceInfo?.serialNumber,
      data: {},
    });
  }

  return (
    <div className="debug-panel">
      <CollapsablePanel title="Remote Debugging" collapsed={collapsed}>
        <div className="debug-actions">
          <Button onClick={sendActivateRemoteDebugging}>
            <TbBolt className='icon' /> Start Debug
          </Button>
          <Button onClick={sendDeactivateRemoteDebugging}>
            <TbBolt className='icon' /> Stop Debug
          </Button>
          <Button onClick={sendTurnOnDrone}>
            <TbBolt className='icon' /> Turn on Drone
          </Button>
          <Button onClick={sendTurnOffDrone}>
            <TbBolt className='icon' /> Turn off Drone
          </Button>
          <Button onClick={missionController?.startDrc}>
            <TbBolt className='icon' /> Start DRC
          </Button>
          <Button onClick={missionController?.stopDrc}>
            <TbBolt className='icon' /> Stop DRC
          </Button>
          <Button onClick={missionController?.liveFlight?.sendFlightAuthorityGrab}>
            <TbBolt className='icon' /> Get Flight Authority
          </Button>
          <Button onClick={sendOpenCover}>
            <TbBolt className='icon' /> Open Cover
          </Button>
          <Button onClick={sendCloseCover}>
            <TbBolt className='icon' /> Close Cover
          </Button>
          <Button onClick={sendReturnHome}>
            <TbBolt className='icon' /> RTH
          </Button>
          <Button onClick={sendCancelReturnToHome}>
            <TbBolt className='icon' /> RTH Cancel
          </Button>
          <Button onClick={sendStopFlyto}>
            <TbBolt className='icon' /> Stop Flyto
          </Button>
          <Button onClick={sendTerminateMission}>
            <TbBolt className='icon' /> Terminate Mission
          </Button>
          <Button onClick={sendReboot}>
            <TbBolt className='icon' /> Reboot
          </Button>
        </div>
      </CollapsablePanel>
    </div>
  )
}
