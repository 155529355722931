import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import useCreateMission, { prepareMissionWaypoints } from './useCreateMission';
import { useIssueMission } from './useIssueMission';
import useMissionInfo from './useMissionInfo'
import { MissionPendingRequestStatus, MissionPendingRequestType, MissionStatus, onboardWaypointAction } from './missionConstants';
import { dockConnectionManager, frontendConnectionManager, onboardConnectionManager } from 'src/helper/HubConnectionManager';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import { getCoordinatesDistanceAndTime } from 'src/helper/waypointMarkupUtils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { deviceTypes } from 'src/helper/constants';
import useCloudLiveFlight from './useCloudLiveFlight';
import useDialog from 'src/helper/useDialog';
import { removeMission, setCreateMissionProgress, setMissionInfo, setMissionPendingRequest } from '../MissionServiceSlice';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import { createKmz } from './missionUtils';
import { useDispatch, useSelector } from 'react-redux';
import { generateId } from 'src/helper/utils';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import { djiCloudCustomMethod } from 'src/services/common/constants';
import rfdc from 'rfdc';
import { getDeviceConnectionGroups, getDeviceInfo, getDeviceTelemetries } from 'src/services/device/common/deviceUtils';
import { setLiveFlight } from 'src/services/flight/FlightServiceSlice';

export default function useMissionController(queryFunction, parentDeviceId) {
  const dialog = useDialog();
  const dispatch = useDispatch();
  const clone = rfdc();
  const missionDetails = useMissionInfo(queryFunction);
  const deviceId = missionDetails?.deviceId || parentDeviceId;
  const { createMission, createMissionResult, loading: createMissionLoading } = useCreateMission();
  const { sendIssueMission, issueResult } = useIssueMission();
  const deviceInfo = useDeviceInfo(deviceId);
  const liveFlight = useCloudLiveFlight(deviceId);
  const connection = useDeviceConnection(deviceId);
  const telemetries = useDeviceTelemetries(deviceId);
  const subDeviceTelemetries = useDeviceTelemetries(telemetries?.sub_device?.device_sn);
  const { hub: frontendHub, group: frontendGroup } = useFrontendHub();
  const frontendOid = useSelector((state) => state.api.state?.oid);
  const onboardHandlerIds = useRef([]);
  const dockHandlerIds = useRef([]);

  // calculate progress
  const progress = useMemo(() => {
    if(!missionDetails || !telemetries) return {};

    const isMissionCompleted = missionDetails?.status === MissionStatus.COMPLETED;
    const waypoints = missionDetails?.waypoints;

    let totalTime = missionDetails?.duration;
    let totalDistance = missionDetails?.distance;
    let lastWaypoint = waypoints?.[waypoints?.length - 1];
    let reportedRemainingTime = missionDetails?.remainingTime ?? lastWaypoint?.remainingTime;
    let reportedRemainingDistance = missionDetails?.remainingDistance ?? lastWaypoint?.remainingDistance;
    let remainingTime, remainingDistance;

    const {
      time: _totalTime, 
      distance: _totalDistance
    } = getCoordinatesDistanceAndTime(
      waypoints?.slice(-2)?.map(item => ({
        lat: item.latitude,
        long: item.longitude
      })), 
      missionDetails?.speed || 10
    );

    totalDistance = _totalDistance;
    totalTime = _totalTime;

    if(missionDetails.status !== MissionStatus.EXECUTING && missionDetails.status !== MissionStatus.RETURNING) {
      remainingTime = totalTime = 0;
      remainingDistance = totalDistance = 0;
    } else if(isNaN(reportedRemainingTime) || isNaN(reportedRemainingDistance)) {
      let currentGpsCoords;

      if(deviceInfo?.type === deviceTypes.DRONE)
        currentGpsCoords = {latitude: telemetries?.gps?.lat, longitude: telemetries?.gps?.long};
      else if(deviceInfo?.type === deviceTypes.DOCK)
        currentGpsCoords = {latitude: (subDeviceTelemetries || telemetries)?.latitude, longitude: (subDeviceTelemetries || telemetries)?.longitude};

      const lastWaypointCoords = { lat: lastWaypoint?.latitude, long: lastWaypoint?.longitude };
      const currentCoords = { lat: currentGpsCoords?.latitude, long: currentGpsCoords?.longitude };

      const estimate = getCoordinatesDistanceAndTime([
        currentCoords,
        lastWaypointCoords
      ], missionDetails?.speed || 10);

      remainingTime = estimate?.time;
      remainingDistance = estimate?.distance;

      // console.log({
      //   title: '🚧🚧🚧',
      //   coords: [
      //     currentCoords,
      //     lastWaypointCoords
      //   ],
      //   remainingTime,
      //   remainingDistance,
      //   totalDistance,
      //   totalTime
      // })
    }
    else {
      remainingTime = reportedRemainingTime;
      remainingDistance = reportedRemainingDistance;
    }

    

    const calculatedProgress = 100 - (remainingDistance / totalDistance) * 100;
    const progressPercent = isMissionCompleted ? 100 :
          calculatedProgress > 1 ? calculatedProgress : 0;

    return {
      percent: progressPercent?.toFixed(0),
      remainingDistance: isMissionCompleted ? false : remainingDistance?.toFixed(1),
      remainingTime: Math.round(remainingTime),
      totalDistance: totalDistance?.toFixed(1),
      totalTime: Math.round(totalTime),
    }
  }, [missionDetails, telemetries]);

  useEffect(() => {
    if(createMissionResult === false) {
      dialog.fire({
        title: <b>Issue Mission</b>,
        text: "Failed to register the mission on the server, please try again later.",
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      dispatch(setCreateMissionProgress(null));
      return;
    } 
    else if(!createMissionResult) return;

    let missionData;

    if(createMissionResult.deviceType === deviceTypes.DRONE) {
      if(missionDetails) {
        missionData = {
          ...missionDetails, 
          status: MissionStatus.PREPARING,
          statusDescription: 'Going to issue the mission...',
        };
      }

      onboardConnectionManager.unsubscribeGroupMessages(onboardHandlerIds.current);
      onboardHandlerIds.current = onboardConnectionManager.subscribeGroupMessages([
        {
          identity: deviceId,
          name: [connection?.group?.join?.onDemandChannel],
          handler: (data) => {
            console.log('🍭🍭🍭🍭🍭', data)
            if(
              data?.action === 'WaypointResult' &&
              data.actionId === onboardWaypointAction.MISSION_INIT
            ) {
              if(data.status) {
                console.log('✨ Mission data synced to drone', data);
                //sendIssueMission(createMissionResult.deviceId || createMissionResult.dockId, createMissionResult);
              }
              else {
                console.log('❌ Mission data sync failed', data);
              }
            }

            onboardConnectionManager.unsubscribeGroupMessages(onboardHandlerIds.current);
          },
        },
      ], 'mission-issue');

      onboardConnectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
        waypoint: {
          actionId: onboardWaypointAction.MISSION_INIT,
          ...missionData,
          waypoints: missionDetails?.waypoints?.[0] ? [missionDetails?.waypoints[0]] : [],
        }
      });

      broadcastMissionToFrontend(missionData);

      setTimeout(() => {
        // dispatch(setMissionPendingRequest({
        //   missionId: createMissionResult?.id,
        //   requestType: MissionPendingRequestType.ONBOARD_ENTER_MISSION,
        //   requestStatus: MissionPendingRequestStatus.PENDING
        // }));

        sendIssueMission(createMissionResult.deviceId || createMissionResult.dockId, createMissionResult);
      }, 2000);
    }
    
    if(createMissionResult.deviceType === deviceTypes.DOCK) {
      const waypoint = createMissionResult.waypoints?.slice(-1)[0];

      if(telemetries?.drone_in_dock && telemetries?.mode_code === 0) {
        dialog.fire({
          title: <b>Drone Takeoff</b>,
          text: "Drone is inside the dock, do you want to start takeoff?",
          icon: 'question',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Takeoff',
        }).then(result => {
          if (result.isConfirmed) {
            liveFlight.sendFlightAuthorityGrab(data => {
              // if(data?.result !== 0) {
              //   console.log('❌ Flight authority grab failed', data);
              //   terminateRemoteCloudMission(missionData.deviceId);

              //   dialog.fire({
              //     title: <b>Drone Takeoff</b>,
              //     text: "Failed to grab flight authority, please try again later.",
              //     icon: 'error',
              //     showConfirmButton: true,
              //     confirmButtonText: 'OK',
              //   });

              //   return;
              // }

              console.log('☑️☑️ after get authority', data);
              
              if(missionDetails) {
                missionData = {
                  ...missionDetails, 
                  status: MissionStatus.PREPARING,
                  statusDescription: 'Takeoff request sent...',
                };
  
                dispatch(setMissionInfo(missionData));
                console.log('☑️☑️ after set mission details', missionData);
              }

              const additionalData = {};

              if(missionDetails?.missionDetails?.securityTakeoffHeight) {
                additionalData.security_takeoff_height = missionDetails?.missionDetails?.securityTakeoffHeight;
              }

              if(missionDetails?.missionDetails?.rthAltitude) {
                additionalData.rth_altitude = missionDetails?.missionDetails?.rthAltitude;
              }
  
              initDockMission(missionData, () => {
                console.log('☑️☑️ after mission init, before takeoff', data);
                liveFlight.sendOneKeyTakeoff({
                  max_speed: createMissionResult.speed,
                  target_height: createMissionResult.altitude,
                  target_latitude: waypoint?.latitude,
                  target_longitude: waypoint?.longitude,
                  distance: 10,
                  duration: 5,
                  flight_id: missionData?.flightId,
                  ...additionalData,
                });

                dispatch(setMissionPendingRequest({
                  missionId: missionDetails?.id,
                  requestType: MissionPendingRequestType.CLOUD_TAKEOFF_PREPARATION,
                  requestStatus: MissionPendingRequestStatus.PENDING
                }));
  
                broadcastMissionToFrontend(missionData);
              });
            });

            if(missionDetails) {
              missionData = {
                ...missionDetails, 
                status: MissionStatus.PREPARING,
                statusDescription: 'Aquring flight authority...',
              };

              dispatch(setMissionInfo(missionData));
            }
          }
          else {
            dispatch(removeMission({ id: createMissionResult.id }));
            terminateRemoteCloudMission(createMissionResult?.deviceId);
          }
        });
      }
      else if(false && telemetries?.mode_code !== 3) {
        // we don't start mission if drone is already in the air
        if(missionDetails) {
          missionData = {
            ...missionDetails, 
            status: MissionStatus.PREPARING,
            statusDescription: 'Mission request sent...',
          };

          setMissionInfo(missionData);
        }

        initDockMission(missionData, () => {
          liveFlight.sendFlyToPoint({
            max_speed: createMissionResult.speed,
            distance: 10,
            duration: 5,
            points: [{
              latitude: waypoint?.latitude,
              longitude: waypoint?.longitude,
              height: createMissionResult.altitude,
            }],
          });

          broadcastMissionToFrontend(missionData);
        });
      } 
      else {
          dialog.fire({
            title: <b>Drone Takeoff</b>,
            text: "The dock is not ready to start takeoff at the moment or malfunctioned. we'll terminate the mission. please refresh the page in case of any issues.",
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'OK',
          });

          if(missionData) {
            terminateRemoteCloudMission(missionData.deviceId);
            dispatch(removeMission({ id: missionData.id }));
          }
      }
    }
  }, [createMissionResult]);

  useEffect(() => {
    return () => {
      onboardConnectionManager.unsubscribeGroupMessages(onboardHandlerIds.current);
      // dockConnectionManager.unsubscribeGroupMessages(dockHandlerIds.current);

      const handlersStorageId = 'mission_controller_dockHandlerIds_' + deviceId;
      localStorage.setItem(handlersStorageId, JSON.stringify(dockHandlerIds.current));
    }
  }, []);

  const broadcastMissionToFrontend = (missionData) => {
    if(missionData) {
      frontendHub.sendToGroup(frontendOid, frontendGroup?.send?.broadcast, {
        bid: generateId(),
        method: 'mission_create',
        data: missionData,
      });
    }
  }

  const createMissionHandler = (...params) => {
    const deviceInfo = getDeviceInfo(deviceId);
    const deviceTelemetries = getDeviceTelemetries(deviceId);

    // check device battery percent
    if(
      deviceInfo.type === deviceTypes.DOCK && 
      deviceTelemetries?.drone_charge_state?.capacity_percent < 35
    ) {
      dialog.fire({
        title: <b>Low Battery</b>,
        text: "Drone battery is low, please charge the drone before starting the mission.",
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      return;
    }

    // check dock mode code
    if(
      deviceInfo.type === deviceTypes.DOCK && 
      deviceTelemetries?.mode_code !== 0
    ) {
      dialog.fire({
        title: <b>Drone Takeoff</b>,
        text: "The dock is not ready to start takeoff at the moment or malfunctioned.",
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      return;
    }
    
    createMission(...params);
  }

  const initDockMission = (missionData, onSuccess) => {
    const  {kmz, ...simpleFlightDetails } = missionData.flightDetails;
    missionData.flightDetails = simpleFlightDetails;

    console.log('☑️☑️ inside initDockMission', missionData);

    const handlersStorageId = 'mission_controller_dockHandlerIds_' + deviceId;
    const handlerIds = localStorage.getItem(handlersStorageId) ? JSON.parse(localStorage.getItem(handlersStorageId)) : [];
    localStorage.removeItem(handlersStorageId);

    dockConnectionManager.unsubscribeGroupMessages([...handlerIds, ...dockHandlerIds.current]);
    dockHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.commands_reply],
        handler: (message) => {
          console.log('☑️☑️ mission_init result handler', missionData);
          if(message?.method !== djiCloudCustomMethod.mission.MISSION_INIT) return;
          // if(message?.tid !== tid) return;

          // if(message?.result !== 0) {
          //   console.log('❌ Mission data sync failed', message);
          //   return;
          // }

          console.log('✨ Mission data synced to dock', message);
          typeof onSuccess === 'function' && onSuccess();
          dockConnectionManager.unsubscribeGroupMessages(dockHandlerIds.current);
        },
      }
    ], 'mission-controller');

    const { tid } = dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.commands, {
      method: djiCloudCustomMethod.mission.MISSION_INIT,
      data: missionData,
      gateway: deviceInfo?.serialNumber,
    });
  }

  const flyToPoint = (reqInfo) => {
    if(!reqInfo || !reqInfo.missionId || !missionDetails) return;

    const waypoints = prepareMissionWaypoints(missionDetails?.deviceId, reqInfo?.waypoints, reqInfo?.speed, reqInfo?.altitude);

    const {
      distance,
      time
    } = getCoordinatesDistanceAndTime(waypoints.map(item => ({
      lat: item.latitude,
      long: item.longitude
    })), missionDetails?.speed);

    let missionData = {
      ...missionDetails,
      status: MissionStatus.PREPARING,
      statusDescription: "Fly to point request sent...",
      waypoints,
      duration: Math.round(time),
      distance: Math.round(distance),
      speed: reqInfo.speed,
    }

    if(missionDetails.deviceType === deviceTypes.DRONE) {
      const kmzData = {
        droneEnumValue: missionDetails?.deviceDetails?.droneEnumValue,
        droneSubEnumValue: missionDetails?.deviceDetails?.droneSubEnumValue,
        payloadEnumValue: missionDetails?.deviceDetails?.payloadEnumValue,
        payloadSubEnumValue: missionDetails?.deviceDetails?.payloadSubEnumValue,
        speed: reqInfo.speed,
        altitude: reqInfo.altitude,
        waypoints,
      };

      createKmz(kmzData).then((content) => {
        try {
          missionData.flightDetails = clone(missionData.flightDetails);

          if(missionData.flightDetails)
            missionData.flightDetails.kmz = content;
  
          sendIssueMission(missionDetails.deviceId, missionData);
        } catch (error) {
          console.error('❌ Error creating kmz', error, {missionData});
        }
      });
    }
    else if(missionDetails.deviceType === deviceTypes.DOCK) {
      dispatch(setMissionPendingRequest({
        missionId: missionDetails?.id,
        requestType: MissionPendingRequestType.CLOUD_FLY_TO_POINT,
        requestStatus: MissionPendingRequestStatus.PENDING
      }));

      liveFlight.sendFlyToPoint({
        max_speed: reqInfo.speed,
        duration: Math.round(time),
        distance: Math.round(distance),
        points: [{
          latitude: reqInfo.latitude,
          longitude: reqInfo.longitude,
          height: reqInfo.altitude,
        }],
      });
    }

    const finalMissionData = {
      ...missionData,
      waypoints: [
        ...missionDetails.waypoints,
        ...waypoints.slice(1),
      ]
    };

    dispatch(setMissionInfo(finalMissionData));
    frontendHub.sendToGroup(frontendOid, frontendGroup?.send?.broadcast, {
      bid: generateId(),
      method: 'mission_update',
      data: finalMissionData,
    });
  }

  const finishMission = () => {
    if(missionDetails.deviceType === deviceTypes.DRONE) {
      sendOnboardMissionAction(onboardWaypointAction.MISSION_TERMINATE);
    }

    if(missionDetails.deviceType === deviceTypes.DOCK) {
      terminateRemoteCloudMission(deviceId);
    }

    frontendHub.sendToGroup(frontendOid, frontendGroup?.send?.broadcast, {
      bid: generateId(),
      method: 'mission_finish',
      data: { 
        missionId: missionDetails.id,
        flightId: missionDetails.flightId,
      },
    });

    dispatch(removeMission({ id: missionDetails.id }));
  }

  const terminateRemoteCloudMission = (deviceId) => {
    const deviceInfo = getDeviceInfo(deviceId);
    const groups = getDeviceConnectionGroups(deviceId);

    dockConnectionManager?.sendToDjiCloudGroup(deviceId, groups?.send?.commands, {
      method: djiCloudCustomMethod.mission.MISSION_TERMINATE,
      gateway: deviceInfo?.serialNumber,
      data: {},
    });
  }

  const sendOnboardMissionAction = (action) => {
    onboardConnectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
      waypoint: {
        actionId: action,
        missionId: missionDetails.id,
      },
    });
  }

  const stopMission = () => {
    if(missionDetails?.deviceType === deviceTypes.DOCK)
      liveFlight.sendStopFlyto();
    else {
      sendOnboardMissionAction(onboardWaypointAction.STOP);
    }
  }

  const startMission = () => {
    sendOnboardMissionAction(onboardWaypointAction.START);
  }

  const pauseMission = () => {
    sendOnboardMissionAction(onboardWaypointAction.PAUSE);
  }

  const resumeMission = () => {
    dispatch(setMissionPendingRequest({
      missionId: missionDetails?.id,
      requestType: MissionPendingRequestType.ONBOARD_RESUME_MISSION,
      requestStatus: MissionPendingRequestStatus.PENDING,
    }));
        
    sendOnboardMissionAction(onboardWaypointAction.RESUME);
  }

  const rth = () => {
    if(deviceInfo?.type === deviceTypes.DOCK)
      liveFlight.sendReturnHome();
    else
      onboardConnectionManager.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
        flightControl: {
          actionId: 9,
          altitude: 80,
        }
      });
  }

  const cancelRth = () => {
    if(deviceInfo?.type === deviceTypes.DOCK)
      liveFlight.sendCancelReturnHome();
    else
      onboardConnectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
        flightControl: {
          actionId: 10,
        }
      });
  }

  const cancelLanding = () => {
    onboardConnectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
      flightControl: {
        actionId: 7,
      }
    });
  }

  const startDrc = () => {
    liveFlight.sendStartDrcChannel({});
  }

  const stopDrc = () => {
    liveFlight.sendStopDrcChannel({});
  }

  return missionDetails?.deviceType === deviceTypes.DRONE ? {
    details: missionDetails,
    progress,
    sendIssueMission,
    issueMissionResult: issueResult,
    createMission: createMissionHandler,
    createMissionResult,
    createMissionLoading,
    stopMission,
    pauseMission,
    resumeMission,
    startMission,
    flyToPoint,
    finishMission,
    rth,
    cancelRth,
    cancelLanding,
  } : {
    details: missionDetails,
    progress,
    sendIssueMission,
    issueMissionResult: issueResult,
    createMission: createMissionHandler,
    createMissionResult,
    createMissionLoading,
    stopMission,
    flyToPoint,
    rth,
    cancelRth,
    liveFlight,
    startDrc: startDrc,
    stopDrc: stopDrc,
  };
}
